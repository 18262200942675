import React from "react"

import Layout from "components/Layout/de"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./AssemblyControl.svg"
import { Table } from "react-bootstrap"
import { MdDone } from "react-icons/md"

export default function(props) {
  const description = <React.Fragment><p>Mit MoonVision AssemblyControl steht Ihnen ein Werkzeugset für die Fertigung zur Verfügung, mit dem individuell gefertigte Bauteile nach deren Zusammensetzung, Positionierung und Ausrichtung hin automatisiert überprüft werden. Dabei erfolgt auf Wunsch auch ein Soll/Ist Vergleich mit ihren bestehenden Datenquellen. Kunden profitieren von einer stabilen und zuverlässigen Qualitätsprüfung in ihrem Produktionsprozess und stark verkürzten Durchlaufzeiten.</p>
  <h3>Highlights</h3>
  <ul>
    <li>Automatisierte Kontrolle individuell zusammengesetzter Bauteile</li>
    <li>Von gleichbleibender Qualität und verkürzter Durchlaufzeit profitieren</li>
    <li>Erkennung und Prüfung mittels Kamera & Software</li>
    <li>Höchste Genauigkeit & Komfort durch Künstliche Intelligenz</li>
    <li>Auswertungen an Folgesysteme oder visuelle Darstellung</li>
  </ul></React.Fragment>
  return (
    <Layout location={props.location}>
      <Product
        id="AssemblyControl"
        title="Automatisierte Prüfung zusammengesetzter Produkte in Echtzeit"
        subtitle="Automatisierte Qualitätskontrolle von Montageprodukten"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automatisierte Bauteilprüfung",
          "Gleichbleibend hohe Qualität",
          "Verkürzte Durchlaufzeit",
          "Jetzt Informieren",
        ]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">Überprüfung von:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Alle Materialarten</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Stationäre Gegenständen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Bewegende Gegenständen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Einzelnen Produktbestandteilen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Gesamtprodukt</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Größe</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Position</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            Mit MoonVision AssemblyControl steht Ihnen ein Werkzeugset für die
            Fertigung zur Verfügung, mit dem individuell gefertigte Bauteile
            nach deren Zusammensetzung, Positionierung und Ausrichtung hin
            automatisiert überprüft werden. Dabei erfolgt auf Wunsch auch ein
            Soll/Ist Vergleich mit ihren bestehenden Datenquellen. Kunden
            profitieren von einer stabilen und zuverlässigen Qualitätsprüfung in
            ihrem Produktionsprozess und stark verkürzten Durchlaufzeiten.
          </p>
          <p>
            MoonVision AssemblyControl unterstützt Sie bei der Prüfung von
            verarbeiteten Bauteilen. Dabei werden neueste Technologien wie
            Machine Learning eingesetzt um verwendete Bauteile anzutrainieren.
            Auf ihre Vorgaben hin werden bauteile auf ihre Positionierung
            Ausrichtung und Zusammensetzung hin geprüft. Dabei kann das System
            auch Prüfen ob elektronische Bauteile korrekt miteinander verbunden
            wurden. Nach erfolgter Prüfung können die erlangten Informationen
            genutzt werden um zB Prozesse zu Steuern, Warnmeldungen abzugeben
            oder in Statistiken zu fließen.
          </p>
          <p>
            Durch die eingesetzte Künstliche Intelligenz werden bestehende
            Systeme im Hinblick auf die Prüfgenauigkeit in den Schatten gestellt
            – der Bewertungsprozess wird vollautomatisiert und Sie sparen
            wertvolle Zeit.
          </p>
          <p>MoonVision AssemblyControl hilft Ihnen:</p>
          <ul>
            <li>Kritische Durchlaufzeiten signifikant zu verbessern</li>
            <li>Schwer zugänglichen Prüforte zu inspizieren</li>
            <li>Hohe Erkennungsraten zu erreichen</li>
            <li>
              Trotz einer Vielzahl an Prüfbedingungen eine Konstante Qualität zu
              erreichen
            </li>
          </ul>
          <p>
            MoonVision AssemblyControl kann an unterschiedlichen
            Produktionsschritten von der Wareneingangskontrolle bis hin zur
            Warenausgangskontrolle eingesetzt werden. Durch das autarke Design
            kann die Software in einen laufenden Fertigungsprozess bzw.
            vorhandene ERP-Systeme integriert werden.
          </p>
          <p>Durch das MoonVision Konzept</p>
          <ul>
            <li>
              können individuelle Anpassungen selbstständig durchgeführt werden
            </li>
            <li>
              bleibt der Zugang und Kontrolle über die Erkennungsparameter bei
              ihnen
            </li>
          </ul>
          <h3>Überall Einsetzbar</h3>
          <p>
            Der MoonVision AssemblyControl kann alle bestehenden Kamerasysteme
            als Datenquellen nutzen – die den Genicam Standard erfüllen. Gerne
            beraten wir Sie auch bei der Wahl des richtigen Systems. Die
            Überprüfung kann in Echtzeit über unsere Online-Plattform erfolgen.
            Alternativ dazu können wir das System auch vor Ort installieren. In
            beiden Fällen erhalten Sie Zugriff zur MoonVision Toolbox und können
            damit jederzeit Anpassungen zB zur Parameteradaptierung und
            Personalisierung durchführen.
          </p>
          <h3>Einfach in der Anwendung</h3>
          <p>
            Aufgrund des Softwaredesigns ist die Optimierung und Implementierung
            von MoonVision AssemblyControl mit geringem Aufwand vor Ort bei
            Ihnen realisierbar. Bereits nach der Überprüfung weniger Bild/Video
            Daten herrscht Gewissheit über die Qualität, weitere Bilddaten
            können auch zu einem späteren Zeitpunkt ergänzt werden. Alle Daten
            werden zentral gesammelt, gespeichert und sind jederzeit für Sie
            abrufbar.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
